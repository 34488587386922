'use strict';

var progress = require('./../../../app_rleu_core/cartridge/js/progress'),
    bonusProductsView = require('./bonus-products-view');

var timer = {
    id: null,
    clear: function() {
        if (this.id) {
            window.clearTimeout(this.id);
            delete this.id;
        }
    },
    start: function(duration, callback) {
        this.id = setTimeout(callback, duration);
    }
};

var util = require('@foundation/js/util');

function showMiniCartFlyout() {
    $('.mini-cart-content').removeClass('out');
    $('.header-login').addClass('out');
    $('.js-header-acc').addClass('header-acc-hidden');
    var cartheight = $('.mini-cart-wrap').outerHeight();
    $('#wrapper').addClass('bag-active');
    $('.utlity-bkgd-animate').height(cartheight);
    $('.mini-cart-content').attr('aria-hidden', 'false');
    setTimeout(function() {
        $('.mini-cart-wrap').addClass('fadein');
        window.MedalliaSurveytShow = false;
    }, 300); //don't add until slide is started
}

var minicart = {
    init: function() {
        this.$el = $('#mini-cart');
        this.$content = this.$el.find('.mini-cart-content');

        if ($(window).width() > 959) {
            $('#mini-cart').hoverIntent({
                sensitivity: 3, // number = sensitivity threshold (must be 1 or higher)
                interval: 300, // number = milliseconds for onMouseOver polling interval
                timeout: 1000, // number = milliseconds delay before onMouseOut
                over: function(event) {
                    var isATBFlyoutOpened = $(event.target).hasClass('mini-cart-mask') || $(event.target).hasClass('mt-atb-success');
                    if (!isATBFlyoutOpened) {
                        if (Number($('.minicart-quantity').text().trim()) > 0) {
                            if ($('.mini-cart-container').length == 0) {
                                var url = $('.mini-cart-total').attr('data-minicartflyout');
                                $.ajax({
                                    url: url,
                                    data: {
                                        hover: true
                                    },
                                    success: function(resp) {
                                        $('.mini-cart-wrap').append(resp);
                                        showMiniCartFlyout();
                                    }
                                });
                            } else {
                                showMiniCartFlyout();
                            }
                        }
                    }
                },
                out: function() {
                    if ($('#wrapper').hasClass('login-active')) {
                        var loginheight = $('.header-login .header-acc').outerHeight();
                        $('.utlity-bkgd-animate').height(loginheight);
                    } else {
                        $('.utlity-bkgd-animate').height(0);
                    }
                    $('#wrapper').removeClass('bag-active');
                    $('.mini-cart-content').attr('aria-hidden', 'true');
                    $('.mini-cart-wrap').removeClass('fadein');
                    window.MedalliaSurveytShow = true;
                }
            });

        }
        //make chat clickable with keyboard
        $('#mini-cart').on('keydown',function (e) {
            var key = e.which;
            if( key == 32 )  {// the space key code
                e.preventDefault();
                if ($('#wrapper').hasClass('bag-active')) {
                    minicart.close();
                } else {
                    var cartheight = $('.mini-cart-wrap').outerHeight();
                    $('#wrapper').addClass('bag-active');
                    $('.utlity-bkgd-animate').height(cartheight);
                    $('.mini-cart-content').attr('aria-hidden', 'false');

                    setTimeout(function() {
                        $('.mini-cart-wrap').addClass('fadein');
                        window.MedalliaSurveytShow = false;
                    }, 200); //don't add until slide is started
                }
                return false;
            }
        });

    },
    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     */
    show: function(html, $form, $button, originalLabel) {
        $(document).trigger('minicart:show');
    	var self = this;
        this.$el.html(html);
        this.init();
        if($('.quickShop-dialog').is(':visible') && $(window).width() < 960) {
        	$button.hide().parent().append('<a href="'+Urls.cartShow+'" class="button checkout-btn-trigger">'+Resources.CHECKOUT_BUTTON+'</a>');
        	$(".quickview-modal .product-col-2").prepend('<div class="addedtobag-done"><i class="fa fa-check" aria-hidden="true"></i>'+Resources.ADDED_TO_BAG+'</div>');
        	$('.quickShop-dialog').find('.findinstore-link, .also-available-in-section-container, .quickshop-back-trigger, .product-price').hide();
        	$('.quickShop-dialog').find('.promotion-callout').css('color','transparent');
        	$('.continue-shopping').show();
        } else {
        	// addToCartLabels variable created in template
            this.originalLabel = originalLabel || $button.html();
            if (!$button.hasClass('no-animation')) {
                //add item added styles
                $button.html("");
                $button.css('position', 'relative');
                progress.show($button);
                setTimeout(function() {
                    $button.removeClass('additional-add');
                    $button.addClass('item-added').prop('disabled', true);
                    $button.html('<span class="animate-left">' + buttonTitleAdded + '</span>' || '<span class="animate-left">Item Added</span>');
                }, 300);
            }

            if (SitePreferences.ATB_FLYOUT) {
                this.slideRight($form, $button);
            } else {
                this.slide($form, $button);
            }
        }
        bonusProductsView.loadBonusOption();
    },
    /**
     * @function
     * @description Slides down and show the contents of the mini cart
     */
    slide: function($form, $button) {
    	var self = this;
        timer.clear();
        //set mobile display
        if ($(window).width() < 960) {
        	$('.mini-cart-pricing .label').remove();

            $('<div/>').prop('class', 'mini-cart-mask').insertBefore('#main');
        } else {
            $('.mini-cart-content').addClass('hide-full-cart');
        }
        var cartheight = $('.mini-cart-wrap').outerHeight();
        // show the item
        setTimeout(function() {
        	$('#wrapper').addClass('bag-active');
            $('.utlity-bkgd-animate').height(cartheight);
            $('.mini-cart-content').attr('aria-hidden', 'false');
            $('.mini-cart-wrap').addClass('fadein');
            window.MedalliaSurveytShow = false;
        }, 600); //don't start until slide is started
        // if usableNetAssitive is not enabled, after a time out automatically close it
        if (!util.accessibilityEnabled()) {
            timer.start(5000, this.close.bind(this));
        }
        if (!$button.hasClass('no-animation')) {
            //remove item added styles and add new styles to show additional items can be added
            setTimeout(function() {
                $button.removeClass('item-added').prop('disabled', false);
                $button.addClass('additional-add');
                $button.html(Resources.Item_Add_To_Bag);
                $('.mini-cart-mask').remove();
            }, 5000);
        }
        $('.mini-cart-mask').off('click touch').on('click touch', function(e) {
            minicart.close();
            $('.mini-cart-mask').remove();
        });
    },

    fetchRecommendations: function (pid) {
        return new Promise((resolve, reject) => {
            var url = util.appendParamsToUrl(Urls.getAtbRecs, {
                pid: pid,
                cid: 'monetate-add-to-bag-redesign-recs',
                isCartAdd: true,
            });
            $.ajax({
                url: url,
                method: 'GET',
                success: function(response) {
                    if (response.length) {
                        $('.mt-atb-recs').html(response);
                        resolve(); // Resolve the promise
                    } else {
                        $('.mt-atb-recs').remove();
                        resolve(); // Resolve even if no recommendations
                    }
                },
                error: function() {
                    $('.mt-atb-recs').remove();
                    reject(); // Reject the promise
                }
            });
        });
    },

    // Sequential flow
    /**
     * @function
     * @description Monetate ATB Test - Slides from right and show the contents of the mini cart
     */
    slideRight: function ($form, $button) {
        var self = this;
        // Utility function to simulate delay with a Promise
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        timer.clear();
        $('#wrapper').addClass('mt-atb');
        $('<div/>').prop('class', 'mini-cart-mask').insertBefore('.mini-cart-content');

        delay(100)
            .then(() => {
                $('.mini-cart-content').css('display', 'block');
                return delay(400); // Chain the next delay (500 - 100 = 400)
            })
            .then(() => {
                $('#wrapper').addClass('mt-atb-active');
                $('body').addClass('prevent-scroll');
                $('.mini-cart-mask').addClass('opened');
                $('#top-banner').addClass('mt-atb-opened');
                $('.mini-cart-content').attr('aria-hidden', 'false').addClass('hide-full-cart mt-atb-opened').trigger('focus');
                if ($('.mini-cart-product:first-child .mini-alt10').length > 0) {
                    $('.mini-cart-product .mini-lifestyle').addClass('hide-lifestyle');
                }
                const productID = $('#pid', $form).val();

                this.fetchRecommendations(productID)
                    .then(() => {
                        $('.mt-atb-recs').addClass('slide-out');
                        $('.mini-cart-mask, .mt-atb-close, .mini-cart-continue-shopping a')
                            .off('click touchstart')
                            .on('click touchstart', function(e) {
                                e.preventDefault();
                                minicart.mtClose();
                            });
                    })
                    .catch(() => {
                        console.error('Failed to fetch Add-To-Bag Recommendations.');
                    });
                return delay(445); // Chain the next delay (555 - 500 = 55)
            })
            .then(() => {
                // Add checkmark animation
                $('.mt-atb-checkmark').addClass('draw');
                if (!util.accessibilityEnabled()) {
                    // close modal - if user is interacting with modal, reset timer
                    var closeModal = this.mtClose.bind(this);
                    timer.start(8000, closeModal);
                    $('.mini-cart-content, .mt-atb-recs').on('mousemove keypress mousewheel hover touchmove', function () {
                        timer.clear();
                        timer.start(8000, closeModal);
                    });
                }
                if (!$button.hasClass('no-animation')) {
                    return delay(8000); // Delay for item-added reset
                }
            })
            .then(() => {
                if (!$button.hasClass('no-animation')) {
                    $button.removeClass('item-added').prop('disabled', false);
                    $button.addClass('additional-add');
                    $button.html(self.originalLabel);
                }
            })
            .catch((error) => {
                console.error('Error in slideRight sequence:', error);
            });
    },

    /**
     * @function
     * @description Closes the mini cart with given delay
     * @param {Number} delay The delay in milliseconds
     */
    close: function(delay) {
        timer.clear();
        $('#wrapper').removeClass('bag-active');
        $('.utlity-bkgd-animate').height(0);
        $('.mini-cart-content').attr('aria-hidden', 'true');
        setTimeout(function() {
            $('.mini-cart-wrap').removeClass('fadein');
            $('.mini-cart-mask').remove();
            window.MedalliaSurveytShow = true;
        }, 200); //don't remove until slide is started
        setTimeout(function() {
            $('.mini-cart-content').removeClass('hide-full-cart');
        }, 1000); //don't remove until slide is finished
    },

    mtClose: function(delay) {
        timer.clear();
        $('#wrapper').removeClass('bag-active');
        $('body').removeClass('prevent-scroll');
        $('.utlity-bkgd-animate').height(0);
        $('.mini-cart-mask').removeClass('opened');
        $('#top-banner').removeClass('mt-atb-opened');
        $('.mini-cart-content').attr('aria-hidden', 'true').addClass('closing');
        setTimeout(function() {
            $('.mini-cart-content').prop('style', '');
            $('#wrapper').removeClass('mt-atb mt-atb-active');
            $('.mini-cart-content').removeClass('hide-full-cart mt-atb-opened closing');
            $('.mt-atb-recs, .mini-cart-mask').remove();
            $('#mini-cart').trigger('mouseout');
        }, 1000);
    }
};

module.exports = minicart;
