'use strict';

import { gsap } from "gsap";

var ajax = require('./ajax'),
    progress = require('./progress'),
    util = require('./util'),
    dialog = require('./dialog'),
    validator = require('./validator'),
    storelocator = require('./pages/storelocator');

    function saveFavoriteStore() {
        $('.save-favorite-store').on('click touchstart', function(e) {
            e.preventDefault();
            var $el = $(this);
            var storeId = $el.data('storeid');
            var module = $el.data('module');
            var closestStoreId =$('input[name="closestStoreId"]').val();
            var currentFavoriteStoreId =$('input[name="currentFavoriteStoreId"]').val();

            if (module == 'home' || module == 'storedetail') {
                progress.show($el);
            } else if(module == 'findstore') {
                progress.show($el.parents('.store-locator'));
            } else {
                progress.show();
            }
            var url = util.appendParamsToUrl(Urls.saveFavoriteStore, {
                storeId: storeId ? storeId : ''
            });
    
            ajax.load({
                url: url,
                type: 'GET',
                async: false,
                callback: function() {
                    progress.hide();
                    $('input[name="currentFavoriteStoreId"]').val(storeId);
                    switch (module) {
                        case 'home':
                            updateHomeClosestStore($el);
                            break;
                        case 'findstore':

                            var homeModuleChanges = false;
                            if(closestStoreId == currentFavoriteStoreId) {
                                homeModuleChanges = true;
                            }
                            updateFindStoreModal($el,homeModuleChanges);
                            if(storeId == closestStoreId){
                                updateHomeClosestStore($('.your-store-module .save-favorite-store'));
                            }
                            break;
                        case 'storelocator':
                        case 'storedetail':
                        case 'bopisfilter':
                            animateFavStoreBtn($el, module);
                            break;
                        default:
                            break;
                    }
                }
            });
        });
    }

    function updateHomeClosestStore($el) {
        $el.addClass('blue-background');

        var toggleMyStoreChanges = gsap.timeline();

        toggleMyStoreChanges
        .fromTo('.your-store-module .your-store-text-closest-store',
            { opacity:1, duration: 0.3 },
            { opacity: 0, duration: 0.3},0
        )
        .fromTo('.your-store-module .saved-store-text',
            { opacity:0,display:'none', duration: 0.3 },
            { opacity:1,display:'inline', duration: 0.3},0
        )
        .fromTo('.your-store-module .your-store-text-my-store',
            { opacity:0,x:-19, duration: 0.4 },
            { opacity:1,x:0, duration: 0.4},1
        )
        .fromTo('.your-store-module .saved-store-text span',
            { opacity:0,x:-19, duration: 0.4 },
            { opacity:1,x:0, duration: 0.4},1
        )
        .fromTo('.your-store-module .save-favorite-store',
            { opacity:1, display:'flex', duration: 0.3},
            { opacity:0, display:'none', duration: 0.3},2
        )
        .fromTo('.your-store-module .change-favorite-store',
            { opacity:0, display:'none', duration: 0.3, delay:0.4},
            { opacity:1, display:'flex', duration: 0.3, delay:0.4,
            onComplete: function() {
                $('.your-store-module .save-favorite-store .saved-store-text').css({'display':'none', 'opacity':'0'});
                $('.your-store-module .save-favorite-store').removeClass('blue-background');
            }},2
        )
    }

    function updateFindStoreModal($el,homeModuleChanges) {
        if(homeModuleChanges) {
            var toggleClosestStoreChanges = gsap.timeline();

            toggleClosestStoreChanges
            .fromTo('.your-store-module .your-store-text-my-store',
                { opacity:1, duration: 0.3 },
                { opacity: 0, duration: 0.3},0
            )
            .fromTo('.your-store-module .your-store-text-closest-store',
                { opacity:0,x:-19, duration: 0.4 },
                { opacity:1,x:0, duration: 0.4},1
            )
            .fromTo('.your-store-module .change-favorite-store',
                { opacity:1, display:'flex', duration: 0.3},
                { opacity:0, display:'none', duration: 0.3},2
            )
            .fromTo('.your-store-module .save-favorite-store',
                { opacity:0, display:'none', duration: 0.3},
                { opacity:1, display:'flex', duration: 0.3},2
            )
        }

        $el.parents('.store-listing-results-container').find('.save-favorite-store').show();
        $el.hide();

        $('.find-stores-modal .store-listing-results .store-listing-item').removeClass('hide');
        var newFavoriteStore = $el.parents('.store-listing-item').clone(true);
        var storeCount = $el.parents('.store-listing-results').find('.store-listing-item').length;
        if(storeCount == 1) {
            $el.parents('.store-listing-results').find('.store-locator-subheader').addClass('hide');
        }
        $el.parents('.store-listing-item').addClass('hide');
        $el.parents('.store-locator').find('.my-favorite-store-container .store-listing-item-container').empty().html(newFavoriteStore);
        $('.my-favorite-store-container .store-locator-subheader').removeClass('hide');
        $('.scrolling-content').animate({
            scrollTop: 0
        }, 500);

        var yourStoreModuleURL = $('.your-store-module').data('action');
        ajax.load({
            url: yourStoreModuleURL,
            type: 'GET',
            async: false,
            callback: function(data) {
                setTimeout(function(){
                    $('.home-page-slot6').empty().html(data);
                    storelocator.init(false);
                    saveFavoriteStore();
                    displayFindInStoreModal();
                },500);
            }
        });
    }

    function animateFavStoreBtn($el, module) {
        var button = $el;
        var buttonText = $el.find('.saved-store-text');
        var storeBlock = button.closest('.store-listing-storediv');
        var moduleContainer = $('#store-listing-resultscontainer');
        var updateStoreBtn = gsap.timeline();

        // Update Elements for BOPIS Filter
        if (module == 'bopisfilter') {
            storeBlock = button.closest('.findinstore-wrapper-plp');
            moduleContainer = $('#stores-details');
        }

        button.find('.checkmark-confirm').addClass('draw');
        button.find('.save-store, .saved-store').toggleClass('hide');

        updateStoreBtn
        .fromTo(
            ['.my-store', buttonText], {
                opacity: 0,
                x: -20
            },
            {
                display: 'flex',
                opacity: 1,
                x: 0,
                duration: 0.4,
                delay: 1,
                onStart: function() {
                    $('.my-store').removeClass('hide-my-store');
                    $('.save-favorite-store').removeClass('hide').css('display', 'block').css('opacity', '1');
                }
            }
        )
        .to(
            button, {
                opacity: 0,
                display: 'none',
                duration: .5,
                delay: 1,
                onComplete: function() {
                    $('.save-store').removeClass('hide');
                    $('.saved-store').addClass('hide');
                    $('.store-listing-storediv, .findinstore-wrapper-plp').removeClass('my-store-block');
                    storeBlock.addClass('my-store-block');
                    $('.store-locator-subheader, .hide-store-heading').removeClass('hide-store-heading');

                    if ($('.store-listing-storediv:not(.my-store-block)').length === 0) {
                        $('#store-listing-results').addClass('only-fav-store');
                    } else {
                        $('#store-listing-results').removeClass('only-fav-store');
                    }

                    if ($(window).width() > 959) {
                        moduleContainer.animate({
                            scrollTop: 0
                        }, 'slow');
                    } else {
                        if (module == 'bopisfilter') {
                            $('.findInStore-dialog').animate({
                                scrollTop: $('.findInStore-dialog').position().top - 64
                            }, 'slow');
                        } else {
                            $('html').animate({
                                scrollTop: moduleContainer.position().top - 64
                            }, 'slow');
                        }
                    }
                }
            }
        )
    }

    function displayFindInStoreModal() {
        $('.change-favorite-store, .open-favorite-store-modal').click(function(e) {
            e.preventDefault();
            var currentUrl = $(this).data('url');
            var template = $(this).data('template');
            var url = util.appendParamsToUrl(currentUrl, {
                template: template,
            });
            ajax.load({
                url: url,
                type: 'GET',
                async: false,
                callback: function(data) {
                    $('body').addClass('prevent-scroll');
                    if ($(window).width() > 959) {
                        dialog.open({
                            html: data,
                            options: {
                                width: 701,
                                autoOpen: true,
                                modal: true,
                                dialogClass: 'findInStore-dialog find-stores-modal',
                                show: {effect: 'slide', direction:'right', speed: 1000},
                                position: {my: 'right top', at: 'right top', of: window},
                                draggable: false,
                                closeOnEscape:false,
                                clickOutside: false,
                                open: function() {
                                    $('div.findInStore-dialog')
                                        .css('overflow-y', 'auto')
                                        .css('max-height', '100%');

                                    $('button.storesFind').off('click').on('click', function(e) {
                                        e.preventDefault();
                                        progress.show();
                                        $(this).closest('form').find('button[type="submit"]').trigger('click');
                                    });
                                    setTimeout(function(){
                                        progress.hide();
                                        $('div.findInStore-dialog .store-locator').removeClass('hide');
                                    },500);
                                    setTimeout(function() {
                                        findStores();
                                        validator.init();
                                        storelocator.init(true);
                                    },800);
    
                                },
                                beforeClose: function () {
                                    $('body').removeClass('prevent-scroll');
                                }
                            }
                        });
    
                        $('.ui-dialog.find-stores-modal button.ui-dialog-titlebar-close').off('click touchstart').on('click touchstart',function(e){
                            e.preventDefault();
                            $('.ui-dialog.find-stores-modal').hide("slide", { direction: "right" }, 1000);
                            setTimeout(function(){ 
                                $('.ui-widget-overlay').remove();
                                $('body').removeClass('prevent-scroll');
                            },1100);
                            return false;
                        });
                    } else {
                        dialog.open({
                            html: data,
                            options: {
                                autoOpen: false,
                                modal: true,
                                dialogClass: 'findInStore-dialog find-stores-modal',
                                draggable: false,
                                open: function () {
                                    $('div.findInStore-dialog')
                                        .css('overflow-y', 'auto')
                                        .css('max-height', '100vh');
    
                                    setTimeout(function(){ 
                                        progress.hide();
                                        $('div.findInStore-dialog .store-locator').removeClass('hide');
                                    },500);
                                    setTimeout(function(){ 
                                        findStores();
                                        validator.init();
                                        storelocator.init(true);
                                    },800);
    
                                },
                                beforeClose: function () {
                                    $('body').removeClass('prevent-scroll');
                                }
                            }
                        });
                    }

                    $('#dwfrm_storelocator').on('keypress', function (event) {
                        var keyPressed = event.keyCode || event.which;
                        if (keyPressed === 13) {
                            event.preventDefault();
                            $('.find-stores-modal #dwfrm_storelocator').find('.storesFind').trigger('click');
                        }
                    });
                }
            });
        });
    }

    function findStores(){
        $(document).on('click','.international-button, .country-button',function(e){
            e.preventDefault();
            var $el= $(this);
            var $input = $el.closest('fieldset').find('select.country').length ? $el.closest('fieldset').find('select.country') : $el.closest('fieldset').find('.searchKey ');
            $input.trigger('blur');
            if($input.hasClass('error')) {
                return false;
            } else {
                var formURL = $el.parents('form').data('action');
                var data = $el.parents('form').serialize();
                 progress.show($el.parents('.store-locator'));
                ajax.load({
                    url: formURL,
                    type: 'GET',
                    async: false,
                    data: data,
                    callback: function(data) {
                        $('.findInStore-dialog .store-locator-result').empty().append(data);
                        progress.hide();
                        if ($el.hasClass('country-button')) {
                            $('.international, .searchdivider-left, .searchdivider-middle, .searchdivider-right ').addClass('hide');
                        } else if($el.hasClass('international-button')) {
                            $('.within-country, .searchdivider-left, .searchdivider-middle, .searchdivider-right ').addClass('hide');
                        }
                        setTimeout(function(){
                            storelocator.init(true);
                            saveFavoriteStore();
                        },800);
                    }
                });
            }
        });
    }

    var favoriteStore = {
        init: function() {
            saveFavoriteStore();
            displayFindInStoreModal();
        }
    };
    
    module.exports = favoriteStore;