'use strict';

/**
 * This file represents an implementation of Toaster/Modal which supports multiple levels (next level toaster on top of the previous one).
 *
 * The content to render the next level toaster/modal is retrieved in below 2 ways:
 * - STATIC - the next level toaster/modal content is static HTML code, already available in `hidden` state in current page DOM.
 * - DYNAMIC - the next level toaster/modal content is retrieved via AJAX call to URL in clicked link
 *
 * Below are some example of markup design to use with this Toaster/Modal engine for SINGLE as well as MULTI level toasters:
 * (The examples below reference to existing `returnPolicyModal.isml` markup & how to modify it to add multi-level toaster/modal)
 *
 * @example <caption>Example of single level toaster/modal. This modal will be opened by some user action, and it is a single level toaster, i.e. no links to open 2nd level toaster.</caption>
 * <div class="flyout-container hide">
 *   <div class="flyout-header">
 *       <h2 class="flyout-title">
 *           ${Resource.msg('returnPolicy.modal.title','checkout',null)}
 *       </h2>
 *   </div>
 *    <div class="flyout-body">
 *      <div class="flyout-body-inner">
 *       <iscontentasset aid="return-policy-top-copy"/>
 *       .......
 *     </div>
 *   </div>
 * </div>
 *
 * @example <caption>Example of multi level toaster/modal with STATIC content for next(2nd) levels. Here the link to be clicked to show 2nd level toaster content should have a class `js-modal-next-level-link`, and the static hidden content should be a sibling of this link & have class `js-modal-next-level-content`.</caption>
 * <div class="flyout-container hide">
 *   <div class="flyout-header">
 *       <h2 class="flyout-title">
 *           ${Resource.msg('returnPolicy.modal.title','checkout',null)}
 *       </h2>
 *   </div>
 *    <div class="flyout-body">
 *      <div class="flyout-body-inner">
 *       .......
 *        <div class="flyout-section">
 *           // LEVEL-2 Toaster link
 *           <h4 class="flyout-section-title">
 *            <button class="js-modal-next-level-link">
 *              Trigger Level 2
 *            </button>
 *           </h4>
 *
 *           <p>Summary of additional Details. Click above link for more details.</p>
 *           // LEVEL-2 Toaster static hidden content
 *           <div class="hide js-modal-next-level-content">
 *            <div class="flyout-body-inner">
 *             <div class="flyout-section">
 *               <p>Additional info paragraph 2. Big paragraph to test. this is a test paragraph.</p>
 *             </div>
 *            </div>
 *           </div>
 *        </div>
 *     </div>
 *   </div>
 * </div>
 *
 * @example <caption>Example of multi level toaster/modal with DYNAMIC content for next(2nd) level. Here we should have a anchor tag (with class `js-modal-next-level-link`), which when clicked on - will open next level toaster with content fetched from the anchor href</caption>
 *  <div class="flyout-container hide">
 *   <div class="flyout-header">
 *       <h2 class="flyout-title">
 *           ${Resource.msg('returnPolicy.modal.title','checkout',null)}
 *       </h2>
 *   </div>
 *    <div class="flyout-body">
 *      <div class="flyout-body-inner">
 *       .......
 *        <div class="flyout-section">
 *           // LEVEL-2 Toaster link
 *           <h4 class="flyout-section-title">
 *            <a
 *               class="js-modal-next-level-link"
 *               href="${URLUtils.url('SAMPLE-ENDPOINT')}"
 *            >
 *               Next Level Toaster Title
 *            </a>
 *           </h4>
 *        </div>
 *     </div>
 *   </div>
 * </div>
 *
 * Note: alternatively in the above example, we can use a <button> instead of <a> - with `data-url` attr holding the actual URL to fetch dynamic content from.
 * For example:
 * // LEVEL-2 toaster link
 * <h4 class="flyout-section-title">
 *  <button
 *   class="js-modal-next-level-link"
 *   data-url="${URLUtils.url('SAMPLE-ENDPOINT')}"
 *  >
 *  Next Level Toaster Title
 * </h4>
 *
 * @example <caption>Above examples cite 2 levels of Toaster/Modal content markup structure as examples, but the same markup structure can be used to render more than 2 levels of toaster/modals as well. Below is one such example of 3 level static content toaster</caption>
 * <div class="return-policy-modal hide">
 *   <div class="return-policy-flyout-header">
 *       <h2 class="return-policy-title">
 *           ${Resource.msg('returnPolicy.modal.title','checkout',null)}
 *       </h2>
 *   </div>
 *    <div class="return-policy-flyout-contents-wrapper">
 *      <iscontentasset aid="return-policy-top-copy"/>
 *      .......
 *      // LEVEL-2 Toaster link
 *      <div>
 *         <button class="js-modal-next-level-link">
 *            Level-2 Toaster Title
 *         </button>
 *
 *         // LEVEL-2 Toaster static hidden content
 *         <div class="hide js-modal-next-level-content">
 *             <div>
 *                 Level-2 Static content text/markup of the next level toaster/modal.
 *
 *                 <div>
 *                     // LEVEL-3 Toaster link
 *                     <button class="js-modal-next-level-link">
 *                        Next Level Toaster Title
 *                     </button>
 *
 *                     // LEVEL-3 Toaster static hidden content
 *                     <div class="hide js-modal-next-level-content">
 *                         <div>
 *                             Level-3 Static content text/markup of the next level toaster/modal.
 *                         </div>
 *                     </div>
 *                 </div>
 *             </div>
 *         </div>
 *       </div>
 *   </div>
 * </div>
 *
 * <div class="flyout-container hide">
 *   <div class="flyout-header">
 *       <h2 class="flyout-title">
 *           ${Resource.msg('returnPolicy.modal.title','checkout',null)}
 *       </h2>
 *   </div>
 *   <div class="flyout-section">
 *
 *        // LEVEL-2 Toaster link
 *       <h4 class="flyout-section-title">
 *           <button class="js-modal-next-level-link">
 *               Trigger Level 2
 *           </button>
 *       </h4>
 *
 *       <p>Summary of additional Details. Click above link for more details.</p>
 *
 *       // LEVEL-2 Toaster Content
 *       <div class="hide js-modal-next-level-content">
 *           <div class="flyout-body-inner">
 *               <div class="flyout-section">
 *
 *                   // LEVEL-3 Toaster link
 *                   <h4 class="flyout-section-title">
 *                       <button class="js-modal-next-level-link">
 *                           Trigger Level 3
 *                       </button>
 *                   </h4>
 *
 *                   Level-2 Static content text/markup of the next level toaster/modal.
 *
 *                   // LEVEL-3 Toaster Content
 *                   <div class="hide js-modal-next-level-content">
 *                       <div class="flyout-body-inner">
 *                           <div class="flyout-section">
 *                               Level-3 Static content text/markup of the next level toaster/modal.
 *                           </div>
 *                       </div>
 *                   </div>
 *                   </div>
 *           </div>
 *       </div>
 *   </div>
 * </div>
 *
 * The Title of a toaster of next level is by default the text of the link/CTA which was clicked on to open it.
 * It can be over-ridden by adding a title in the markup itself with class `js-toaster-title`.
 *
 * @example <caption>Example of 2 level toaster, where the next level toaster title will be the value set in <h2> with class `js-toaster-title`</caption>
 * <div class="hide js-modal-next-level-content">
 *     <h2 class="flyout-title js-toaster-title">Overridden title</h2>
 *     <div>
 *         Static content text/markup of the next level toaster/modal.
 *     </div>
 * </div>
 * TODO: check if need to handle keyboard events? like esc key should close toaster - maybe better for accessecibility
 * TODO: add this comment info to Confluence page after markup structure finalized
 */
var initialized = false;
var progress = require('./progress');
const {
    loadDfStyles,
    onDialogEvent,
    getDefaultToasterOpenDirection,
    getRTLDirection,
    registerNextLevelToasterEvent,
    addTitleToToaster,
} = require('./toasterUtils');

var margin, $overlay, currentOpeningDirection, $toasterOrigin;
var isOpening = false;

/**
 * @description Array to hold the open toaster HTML elements.
 * New Toaster element will be pushed to this array when opened, and poped when it is closed.
 * @type {Array<JQuery<HTMLElement>>}
 */
var toasterStack = [];
/**
 * @description Array to hold the origin HTML element of a toaster's static content.
 * For teleports, contains a reference to the element the content was pulled from. Used when closing toasters to restore the content.
 * Key is the toaster using that content
 * @type {Map<JQuery<HTMLElement>, JQuery<HTMLElement>>}
 */
var toasterOriginStack = new Map();

// watchers

var mobileDesignWatcher = window.matchMedia('(max-width:959px)');

function onBreakpoint() {
    closeToaster(null, true);
}

/**
 * @description Closes root toaster and with internal ones.
 * @param {Event} e
 */
async function closeToaster(e, force, noUnlock) {
    if (e) {
        e.stopPropagation();
    }

    if(isOpening && !force) {
        return;
    }

    if (mobileDesignWatcher.removeEventListener) {
        mobileDesignWatcher.removeEventListener('change', onBreakpoint);
    } else {
        mobileDesignWatcher.removeListener(onBreakpoint);
    }

    $(document).off('dialog-evt', onDialogEvent);

    const toasterCount = toasterStack.length;
    for (let i = 0; i < toasterCount; i++) {
        await closeInternalToaster(null, force || toasterStack.length !== 1, noUnlock);
    }
}

/**
 * @description Closes an internal tosater.
 * @param {Event} e
 * @param {boolean} force
 * @param {boolean} noUnlock
 */
function closeInternalToaster(e, force, noUnlock) {
    if (e) {
        e.stopPropagation();
    }

    return new Promise(function (resolve) {
        const $toaster = toasterStack.pop();
        if ($toaster) {
            //_unpassEvents();
            var callback = function (e) {
                const directionToCompare = getRTLDirection(currentOpeningDirection);
                if (!e || e.originalEvent.propertyName === directionToCompare || e.originalEvent.propertyName === 'opacity') {
                    $toaster.off('transitionend', callback);

                    var $toasterOrigin = toasterOriginStack.get($toaster);
                    if ($toasterOrigin) {
                        $toasterOrigin.append($toaster.find('.rl-toaster-content').children());
                    }

                    $toaster.trigger('close');
                    $toaster.remove();
                    if (toasterStack.length === 0) {
                        toasterOriginStack.clear();
                        if (!noUnlock) {
                            $overlay.remove();
                            $overlay = null;
                            $('body').removeClass('toaster-active toaster-prevent-scroll add-scrollbar rtl-toaster');
                            $('#wrapper').removeAttr('inert').css({ 'height': '', 'margin-top': '' });
                            window.scrollTo({
                                top: margin,
                                behavior: 'instant'
                            });
                        }
                        document.dispatchEvent(new Event('toaster:close'));
                    }
                    resolve();
                }
            };

            if (force) {
                callback();
            } else {
                if (toasterStack.length === 0 && !noUnlock) {
                    $overlay.off('click', closeToaster);
                    $overlay.removeClass('opened ');
                }

                $toaster.on('transitionend', callback);
                $toaster.removeClass('opened');
            }
        } else {
            // should never come here
            // document.dispatchEvent(new Event('toaster:close'));
            // resolve();
        }
    });

}

/**
 * @description Opens toaster with cotent passed in parameter.
 * @param {JQuery<HTMLElement>} $htmlContent
 * @param {'right' | 'bottom' | undefined} from
 * @param {boolean} addOverlay
 * @param {*} _forward
 * @param {string} toasterClass
 * @param {boolean} hideClose if true, the toaster content is reaponsible for closing the toaster
 * @param {boolean} [preventPreviousToasterClose]
 */

function openToaster($htmlContent, from, addOverlay, _forward, toasterClass, hideClose, preventPreviousToasterClose, resolveImmediately) {
    isOpening = true;
    return new Promise(async function (resolve) {

        document.dispatchEvent(new Event('toaster:open'));
        currentOpeningDirection = from || getDefaultToasterOpenDirection();
        if (toasterStack.length > 0 && !preventPreviousToasterClose) {
            // close previous tostaers, but keep the overlay and body lock
            await closeToaster(null, true, true);
        } else if (!toasterStack.length) {
            if(window.SitePreferences.RTL_LOCALE && currentOpeningDirection === 'right'){
                document.body.style.setProperty('--vert-scrollbar-width', window.innerWidth - document.documentElement.clientWidth + 'px');
                document.body.classList.add('rtl-toaster')
            } else {
                document.body.style.setProperty('--vert-scrollbar-width', window.innerWidth - document.documentElement.clientWidth + 'px');
            }
        }

        const $toaster = $('<div>', { tabindex: '-1' });
        toasterStack.push($toaster);
        const isMultiLevelToaster = toasterStack.length > 1;

        const toasterClasses = [
            'rl-toaster from-' + currentOpeningDirection,
            isMultiLevelToaster ? 'rl-toaster-internal' : '',
            toasterClass ? toasterClass : ''
        ];

        $toaster.addClass(toasterClasses.join(' '));

        if (!isMultiLevelToaster) {
            // root toaster
            // the overlay
            addOverlay = addOverlay && !$overlay;
            if (addOverlay && hideClose) {
                $overlay = $('<div>', {
                    class: 'rl-toaster-overlay'
                })
                    .appendTo($('body'));
            } else if (addOverlay) {
                $overlay = $('<div>', {
                    class: 'rl-toaster-overlay'
                })
                    .on('click', closeToaster)
                    .appendTo($('body'));
            }
            $('<div>', {
                class: 'rl-toaster-multilevel'
            }).appendTo($toaster);

            margin = $(window).scrollTop();
        } else {
            $('<button>', {
                class: 'rl-toaster-back-btn'
            })
                .on('click', closeInternalToaster)
                .appendTo($toaster);
        }

        $('<div>', {
            class: 'rl-toaster-content'
        }).append($htmlContent).appendTo($toaster);

        if (isMultiLevelToaster) {
            $toaster.appendTo(toasterStack[0].find('.rl-toaster-multilevel'));
        } else {
            // add close button once on root toaster
            if (!hideClose) {
                $('<button>', {
                    class: 'rl-toaster-close js-modal-close-btn'
                })
                    .on('click', closeToaster)
                    .appendTo($toaster);
            }
            $toaster.appendTo($('body'));

            $('body').addClass('toaster-active add-scrollbar toaster-prevent-scroll');

            if (!mobileDesignWatcher.matches) {
                $('#wrapper').css({
                    'height': '100vh',
                    'margin-top': (0 - margin)
                });
            }

            if (mobileDesignWatcher.addEventListener) {
                mobileDesignWatcher.addEventListener('change', onBreakpoint);
            } else {
                mobileDesignWatcher.addListener(onBreakpoint);
            }
            $(document).on('toaster-evt', onDialogEvent);
            $('.ui-tooltip').remove();

            if ($overlay) {
                $overlay.addClass('opened');
            }

            $('#wrapper').attr('inert', true);
        }

        var callback = function (e) {
            const directionToCompare = getRTLDirection(currentOpeningDirection);
            if (e.originalEvent.propertyName == directionToCompare || e.originalEvent.propertyName == 'opacity') {
                $toaster.off('transitionend', callback);
                resolve($toaster);
            }
        };

        if (resolveImmediately) {
            resolve($toaster);
        } else {
            $toaster.on('transitionend', callback);
        }

        setTimeout(function () {
            $toaster.addClass('opened');
            if (!isMultiLevelToaster) {
                document.dispatchEvent(new CustomEvent('toaster:opened', {detail:{marginTop:margin}}));
            }
        }, 250)

        registerNextLevelToasterEvent(currentOpeningDirection, toasterClass, true, teleport, loadToaster);
    }).then(function($toaster) {
        isOpening = false;
        return $toaster;
    });
}

/**
 * @description Loads html content by fetching from server, and then calls `openToaster` method to render it.
 * @param {string} contentURL
 * @param {string} from
 * @param {string} styleStaticPath
 * @param {string} toasterClass
 * @param {boolean} preventPreviousToasterClose
 * @param {JQuery<HTMLElement>} [$nextLevelLink] The link/CTA clicked to trigger this fn & load next level toaster content
 */
function loadToaster(contentURL, from, styleStaticPath, toasterClass, preventPreviousToasterClose, $nextLevelLink, resolveImmediately) {
    return new Promise(function (resolve, reject) {
        if (!contentURL) {
            reject();
        }
        const $loadingContainer = preventPreviousToasterClose && $('.rl-toaster').length ? $('.rl-toaster') : $('#container-wrap');
        progress.show($loadingContainer);
        loadDfStyles(styleStaticPath);
        $.ajax({
            type: 'GET',
            async: true,
            url: contentURL,
            success: function (data) {
                if (typeof (data) != 'string') {
                    progress.hide();
                    reject();
                    return;
                }
                progress.hide();

                let $parsedHtml = $($.parseHTML(data, document));
                if ($nextLevelLink) {
                    $parsedHtml = addTitleToToaster($parsedHtml, $nextLevelLink);
                }

                openToaster(
                    $parsedHtml,
                    from,
                    true,
                    null,
                    toasterClass,
                    false,
                    preventPreviousToasterClose,
                    resolveImmediately
                )
                    .then(resolve);
            },
            error: function (e) {
                console.error('Modal Error ' + contentURL + ' error\n', e);
                progress.hide();
                reject();
            }
        });
    });

}

/**
 * @description Opens a toaster with static content passed as children of the first parameter.
 * @param {JQuery<HTMLElement>} $element
 * @param {'bottom' | 'right'} from
 * @param {*} forward
 * @param {string} toasterClass
 * @param {boolean} hideClose
 * @param {boolean} preventPreviousToasterClose
 */
function teleport($element, from, forward, toasterClass, hideClose, preventPreviousToasterClose, resolveImmediately) {
    return new Promise(function (resolve, reject) {
        openToaster($element.children(), from, true, null, toasterClass, hideClose, preventPreviousToasterClose, resolveImmediately)
            .then($toaster => {
                toasterOriginStack.set($toaster, $element);
                resolve($toaster);
            });
    });
    //_passEvents(forward);
    //return Promise.resolve($toaster);
}

/*
function _forwarder = function (event) {
    $toasterOrigin.dispatchEvent(new event.constructor(event.type, event));
    event.preventDefault();
    event.stopPropagation();
}
function _passEvents(_forward) {
    if (_forward && $toaster && $toasterOrigin) {
        forward = _forward
        forward.forEach(function (eventType) {
            $toaster.addEventListener(eventType, _forwarder);
        });
    }
}
function _unpassEvents() {
    if (forward) {
        forward.forEach(function (eventType) {
            $toaster.removeEventListener(eventType, _forwarder);
        });
    }
}*/

module.exports = {
    close: closeToaster,
    load: loadToaster,
    teleport: teleport,
    closeInternalToaster: closeInternalToaster
};
